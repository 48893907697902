@import url(https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;600&family=Inter:wght@400;500;600;700&family=Space+Grotesk:wght@400;500;600&display=swap);
/* Theme */

:root {
  --beatroot: rgb(161, 60, 60);
  --orange: #ff4f40;
  --indigo: #635dff;
  --white: #fff;
  --light-aluminium: #eaecee;
  --aluminium: #bdc4cf;
  --dark-aluminium: #2a2e35;
  --black: #000000;
  --yellow: #ebca40;
  --mandarine: #ff7f38;
  --pink: #ff44dd;
  --blue: #3885ff;
  --aqua: #3ec6eb;
  --emerald: #1bc99f;

  --yellow-mandarine-gradient: linear-gradient(
    153.07deg,
    var(--yellow) -2.47%,
    var(--mandarine) 102.78%
  );

  --mandarine-orange-gradient: linear-gradient(
    153.07deg,
    var(--mandarine) -2.47%,
    var(--orange) 102.78%
  );

  --pink-yellow-gradient: linear-gradient(
    153.07deg,
    var(--pink) -2.47%,
    var(--yellow) 102.78%
  );

  --pink-indigo-gradient: linear-gradient(
    153.07deg,
    var(--pink) -2.47%,
    var(--indigo) 102.78%
  );

  --indigo-aqua-gradient: linear-gradient(
    153.07deg,
    var(--indigo) -2.47%,
    var(--aqua) 102.78%
  );

  --blue-aqua-gradient: linear-gradient(
    153.07deg,
    var(--blue) -2.47%,
    var(--aqua) 102.78%
  );

  --aqua-emerald-gradient: linear-gradient(
    153.07deg,
    var(--aqua) -2.47%,
    var(--emerald) 102.78%
  );

  --emerald-yellow-gradient: linear-gradient(
    153.07deg,
    var(--emerald) -2.47%,
    var(--yellow) 102.78%
  );

  --font-primary: "Inter", sans-serif;
  --font-secondary: "Space Grotesk", sans-serif;
  --font-mono: "Fira Code", monospace;
}

/* General */

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  text-rendering: geometricPrecision;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--light-aluminium);

  color: var(--black);
  font-family: var(--font-primary);

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-y: scroll;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-secondary);
  font-weight: 600;
  color: rgba(0, 0, 0, 0.86);
}

h1,
h2,
h3 {
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
}

h4,
h5,
h6 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
  color: var(--light-aluminium) ;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.4rem;
}

p {
  margin: 0 0 1.6rem;
}

strong {
  font-weight: 500;
}

small {
  font-size: 1.2rem;
}

blockquote {
  padding: 1.6rem 3.2rem;
  margin: 0 0 3.2rem;

  border-left: 8px solid #eee;

  font-size: 1.6rem;
  font-style: italic;
}

body,
button,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  /* color: var(--white); */
  text-decoration: none;
}

figure {
  margin: 0;
}
img {
  vertical-align: middle;
}

code,
pre {
  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  width: 100%;
}

code {
  color: var(--emerald);
}

#root {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 640px) {
  .mobile-scroll-lock {
    overflow: hidden;
  }
}

.code-snippet {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.8rem;
  margin-top: 3.2rem;
  overflow: hidden;
}

.code-snippet__title {
  height: 4.8rem;
  width: 100%;

  background-color: var(--aluminium);
  color: var(--black);

  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  font-weight: 600;

  /*  responsive */

  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 1.2rem 1.6rem;
}

.code-snippet__container {
  min-height: 32.4rem;
  background-color: var(--dark-aluminium);
  overflow-x: auto;
}

.code-snippet__wrapper {
  display: inline-block;
  padding: 32px;
}

.code-snippet__body {
  margin: 0;

  color: var(--white);
  font-size: 16px;
  line-height: 32px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

@media only screen and (max-width: 480px) {
  .code-snippet__title {
    /*  responsive */

    height: 4.4rem;

    font-size: 1.4rem;
    line-height: 2.2rem;
    padding: 1.2rem 1.6rem;
  }

  .code-snippet__wrapper {
    padding: 1.6rem;
  }

  .code-snippet__body {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

.button {
  border: 0;
  border-radius: 0.8rem;

  font-family: var(--font-primary);
  font-weight: 600;
  color: var(--white);

  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;

  transition: background 0.3s ease-out, color 0.3s ease-out;

  /* responsive */

  min-width: 10rem;
  padding: 1.6rem 1.6rem;

  font-size: 1.6rem;
  line-height: 2.4rem;
}

.button--compact {
  /* responsive */

  padding: 1rem 1.6rem;

  font-size: 1.6rem;
  line-height: 1.6rem;
}

.button--primary {
  background-color: var(--indigo);
}

.button--primary:hover {
  background: rgba(99, 93, 255, 0.85);
}

.button--secondary {
  background-color: var(--white);
  color: var(--black);
}

.button--secondary:hover {
  background: rgba(255, 255, 255, 0.85);
}



@media only screen and (max-width: 376px) {
  .button {
    /* responsive */

    min-width: 0.8rem;
    padding: 1rem 1.2rem;

    font-size: 1.3rem;
    line-height: 2.2rem;
  }

  .button--compact {
    /* responsive */

    padding: 0.6rem 1rem;

    font-size: 1.3rem;
    line-height: 2rem;
  }
}


.button__addEvent__tiny,
.button__login__tiny,
.button__logout__tiny {
  min-width: 4.4rem;

  border: 0.1rem solid var(--indigo);
  color: var(--white);
  background: var(--indigo);
  width: 50%;
  font-size: 1rem;

  margin-right: 1.6rem;

  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  line-height: 1.2rem;
  padding: 0.8rem 0;
  border-radius: 0.8rem;
  text-align: center;

  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;

  transition: background 0.3s ease-out, color 0.3s ease-out;
}

.button__addEvent,
.button__login,
.button__logout {
  min-width: 8.4rem;

  border: 0.1rem solid var(--indigo);
  color: var(--white);
  background: var(--indigo);
  width: 50%;
  font-size: 1.9rem;

  margin-right: 1.6rem;

  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  line-height: 3.2rem;
  padding: 0.8rem 0;
  border-radius: 0.8rem;
  text-align: center;

  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;

  transition: background 0.3s ease-out, color 0.3s ease-out;
}

.button__sign-up {
  min-width: 8.4rem;

  border: 0.1rem solid var(--white);
  color: var(--white);
  background-color: transparent;
  width: 50%;
  font-size: 1.6rem;

  margin-right: 1.6rem;

  font-style: normal;
  font-weight: 500;
  line-height: 3.2rem;
  padding: 0.8rem 0;
  border-radius: 0.8rem;
  text-align: center;

  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.button__addEvent:last-child,
.button__login:last-child,
.button__logout:last-child,
.button__sign-up:last-child {
  margin-right: 0;
}

@media only screen and (min-width: 641px) {
  .button__addEvent,
  .button__login,
  .button__logout,
  .button__sign-up {
    padding: 0.8rem 1.2rem;

    font-size: 1.0rem;
    line-height: 1.1rem;
  }
}

.nav-bar__container {
  position: fixed;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;

  box-shadow: rgb(90 95 102) 0-1.5px 0 inset;

  z-index: 300;
  background-color: var(--beatroot);
}

.nav-bar__container__tiny {
  width: 100%;
  background-color: var(--beatroot);
}

.nav-bar {
  flex: 1 1;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  /* responsive */

  height: 80px;
  max-width: 1200px;

  padding: 0 24px;
  margin: 0;
}

.nav-bar__brand {
  display: flex;
  align-items: center;

  height: 100%;

  /* responsive */

  margin-right: 64px;
}

.nav-bar__link {
  display: flex;
  align-items: center;

  height: 100%;
}

.nav-bar__logo {
  height: 3.2rem;
}

.nav-bar__tabs {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-bar__tab {
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-right: 24px;

  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.nav-bar__tab:last-child {
  margin-right: 0;
}

.nav-bar__tab--active {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  -webkit-text-decoration-color: var(--indigo);
          text-decoration-color: var(--indigo);

  /* responsive */

  text-decoration-thickness: 4px;
  text-underline-offset: 8px;
}

.nav-bar__tab:hover {
  color: var(--white);
}

.nav-bar__icon {
  display: none;
}

.nav-bar__buttons {
  display: flex;
  margin-left: 24px;
}

@media only screen and (max-width: 640px) and (hover: none) {
  .mobile-nav-bar__tab:hover {
    color: var(--white);
  }

  .mobile-nav-bar__tab--active:hover {
    color: var(--white);
  }
}

@media only screen and (max-width: 640px) {
  .nav-bar__container {
    display: none;
  }

  .nav-bar__tab {
    font-size: 1.0rem;
  }

  .nav-bar {
    height: 6.4rem;

    padding: 0.8rem 1.6rem;
  }

  .nav-bar__brand {
    display: flex;
    align-items: center;

    margin-right: 1.6rem;
  }

  .nav-bar__logo {
    height: 3.2rem;
  }

  .nav-bar__tab--active {
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
    color: var(--pink);
  }

  .nav-bar__label {
    display: none;
  }

  .nav-bar__icon {
    display: block;
    font-size: 3.2rem;
  }

  .nav-bar__buttons {
    margin-left: 24px;
  }
}

@media only screen and (max-width: 340px) {
  .nav-bar__tab {
    font-size: 1.3rem;
  }

  .nav-bar {
    height: 6.4rem;

    padding: 0.8rem 1.6rem;
  }

  .nav-bar__brand {
    display: flex;
    align-items: center;

    margin-right: 1.6rem;
  }

  .nav-bar__logo {
    height: 2.8rem;
  }

  .nav-bar__tab--active {
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
    color: var(--pink);
  }

  .nav-bar__buttons {
    margin-left: 24px;
  }
}

.page-layout {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;
}

.page-layout__content {
  flex: 1 1;
  flex-basis: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  margin-top: 8rem;
  max-width: 120rem;
  width: 100%;
}

@media only screen and (max-width: 640px) {
  .page-layout__content {
    margin-top: 6.4rem;
  }
}

.content-layout {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  width: 100%;
  min-height: 640px;

  /* responsive */

  padding: 48px;
}

.content__title {
  margin-top: 0;

  color: var(--black);
}

.content__body {
  /* responsive */

  font-size: 1.6rem;
  line-height: 2.4rem;
}

#page-description {
  display: flex;
  flex-direction: column;
}

#page-description span {
  margin-bottom: 1.6rem;
}

#page-description span:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 480px) {
  .content-layout {
    padding: 1.6rem;
  }

  .content__title {
    font-size: 2.4rem;
  }
}






.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header-Org {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header {
  background-color:rgb(161, 60, 60);
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 1vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

